/**
 * @type {UserConfig}
 */
// var logo = '<img src="https://fontmeme.com/permalink/220805/3b90158d204fdd325173a5c7873e7cc1.png" alt="Logo" loading="lazy" style="margin-left: -4px; margin-bottom: -5px; height: 35px">';
var cookie = '🍪';
const pluginConfig = {
  
    current_lang: 'fr',
    autoclear_cookies: true, // default: false
    page_scripts: true, // default: false

    gui_options: {
        consent_modal: {
            layout: 'box',                      // box,cloud,bar
            position: 'bottom left',           // bottom,middle,top + left,right,center
            transition: 'slide'                 // zoom,slide
        },
        settings_modal: {
            layout: 'box',                      // box,bar
            // position: 'left',                // right,left (available only if bar layout selected)
            transition: 'slide'                 // zoom,slide
        }
    },
  
    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: false,                  // default: false
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0
  
    onFirstAction: function (user_preferences, cookie) {
      // callback triggered only once
      const analyticsEnabled =
        window.CookieConsentApi.allowedCategory('analytics');
      console.log(`analytics ${analyticsEnabled ? 'enabled' : 'disabled'}`);
    },
  
    onAccept: function (cookie) {
      // ...
    },
  
    onChange: function (cookie, changed_preferences) {
      // ...
    },
  
    languages: {
      fr: {
        consent_modal: {
          title: cookie + ' Nous utilisons des cookies ! ',
          description: 'En cliquant sur le bouton « Accepter et fermer » vous consentez à l’utilisation de ces cookies et technologies pour les finalités susvisées. Vous pouvez retirer votre consentement ou vous opposer aux traitements basés sur l’intérêt légitime à tout moment en cliquant sur « Gérer mes cookies ». Plus de détails cliquez sur <button type="button" data-cc="c-settings" class="cc-link">personnaliser</button>.',
          primary_btn: {
              text: 'Tout accepter',
              role: 'accept_all'              // 'accept_selected' or 'accept_all'
          },
          secondary_btn: {
              text: 'Tout refuser',
              role: 'accept_necessary'        // 'settings' or 'accept_necessary'
          }
      },
        settings_modal: {
          title: "Panneau de gestion des cookies",
          save_settings_btn: 'Enregistrer',
          accept_all_btn: 'Tout accepter et fermer',
          reject_all_btn: 'Refuser',
          close_btn_label: 'Fermer',
          cookie_table_headers: [
              {col1: 'Nom'},
              {col2: 'Domaine'},
              {col3: 'Expire'},
              {col4: 'Description'}
          ],
          blocks: [
            {
                title: 'Usage des cookies 📢',
                description: "En autorisant ces services tiers, vous acceptez le dépôt et la lecture de cookies et l'utilisation de technologies de suivi nécessaires à leur bon fonctionnement."
            }, 
            {
                title: 'Cookies obligatoires',
                description: 'Ce site utilise des cookies nécessaires à son bon fonctionnement. Ils ne peuvent pas être désactivés.',
                toggle: {
                    value: 'necessary',
                    enabled: true,
                    readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                }
            }, 
            {
                title: 'Performance et Analytics cookies',
                description: 'Les cookies analytiques sont utilisés pour comprendre comment les visiteurs interagissent avec le site Web. Ces cookies aident à fournir des informations sur les mesures du nombre de visiteurs, du taux de rebond, de la source du trafic, etc',
                toggle: {
                    value: 'analytics',     // there are no default categories => you specify them
                    enabled: false,
                    readonly: false
                },
                cookie_table: [
                    {
                        col1: '^_ga',
                        col2: 'google.com',
                        col3: '2 years',
                        col4: 'Les cookies analytiques sont utilisés pour comprendre comment les visiteurs interagissent avec le site Web. Ces cookies aident à fournir des informations sur les mesures du nombre de visiteurs, du taux de rebond, de la source du trafic, etc',
                        is_regex: true
                    },
                    {
                        col1: '_gid',
                        col2: 'google.com',
                        col3: '1 day',
                        col4: 'Google',
                    },
                    {
                        col1: 'Google Tag Manager',
                        col2: 'Google Tag Manager',
                        col3: '1 day',
                        col4: 'Google Tag Manager',
                    },
                    {
                        col1: 'Hotjar',
                        col2: 'Hotjar',
                        col3: '1 day',
                        col4: 'Hotjar',
                    }
                ]
            }, 
            {
                title: 'Cookies autres',
                description: 'Crisp Chat',
                toggle: {
                    value: 'targeting',
                    enabled: false,
                    readonly: false
                }
            }, 
            {
                title: "Plus d'information",
                description: 'Si vous avez des questions voici <a class="cc-link" href="/nous-connaitre/legal/politique-confidentialite/">notre politique de confidentialité</a>.',
            }
          ],
        },
      },
    },
  };
  
  export default pluginConfig;
  